<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="开票单号">
              <a-input v-model.trim="queryParam.serial_num" placeholder="根据开票单号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单号">
              <a-input v-model.trim="queryParam.order_num" placeholder="根据订单号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="开票状态">
              <a-select
                allowClear
                placeholder="请选择开票状态"
                style="width: 100%"
                v-model="queryParam.fee_status_list"
                mode="multiple"
              >
                <a-select-option v-for="op in feeStatusList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="申请时间">
              <a-range-picker style="width: 100%" show-time @change="onDateChange" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="开票时间">
              <a-range-picker style="width: 100%" show-time @change="onDateChange2" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务类型">
              <a-select
                allowClear
                placeholder="请选择业务类型"
                style="width: 100%"
                v-model="queryParam.order_type"
              >
                <a-select-option v-for="op in orderTypeList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票号">
              <a-input v-model.trim="queryParam.invoice_no" placeholder="根据发票号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="结算单金额">
              <a-input v-model.trim="queryParam.total_amount"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
                mode="multiple"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in buyerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="认领状态">
              <a-select
                allowClear
                placeholder="请选择认领状态"
                style="width: 100%"
                v-model="queryParam.claim_status"
              >
                <a-select-option v-for="op in claimStatusList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="品名">
              <a-input v-model.trim="queryParam.good_name" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="queryList">查询</a-button>
      <a-button type="primary" icon="download" @click="tradeDebtSheet('receivable')">贸易应收表</a-button>
      <a-button type="primary" icon="download" @click="tradeNoInvoicedSheet">贸易预收表</a-button>
      <a-button type="primary" icon="download" @click="tradeDebtSheet('claim')">贸易催收表</a-button>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="index"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data="loadData"
      :alert="{
        show: true,
        msg: showTotal
      }">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="printInvoiceSheet(record, index)">查看开票单</a>
          <a style="margin-left:15px" v-if="record.invoices" @click="handleSendEmail(record)">发送邮件</a>
        </template>
      </span>
      <div slot="businessNo" slot-scope="text">
        <span style="display:block" v-for="item in text" :key="item">{{ item }}</span>
      </div>
      <div slot="feeStatusItem" slot-scope="text, record">
        <a-tag :color="feeStatusColor(record.fee_status)" style="margin-bottom:2px;">
          {{ GLOBAL.feeStatusMaps[record.fee_status] ? GLOBAL.feeStatusMaps[record.fee_status].name : '未提交' }}
        </a-tag>
      </div>
      <div slot="feeItem" slot-scope="text, record">
        <div v-for="item in record.fees" :key="item.id">
          <span v-if="item.pay_target_name">{{ item.pay_target_name }} / {{ item.good_name }} / {{ item.unit }} / {{ item.good_spec }} ~ 数量：{{ item.good_num }} ~ 单价：{{ item.money }} ~ 总价：{{ item.total_money }}</span>
          <span v-else>{{ item.good_name }} / {{ item.unit }} / {{ item.good_spec }} ~ 数量：{{ item.good_num }} ~ 单价：{{ item.money }} ~ 总价：{{ item.total_money }}</span>
        </div>
      </div>
      <div slot="invoiceItem" slot-scope="text, record">
        <div v-for="item in record.invoices" :key="item.id">
          <span v-if="item.invoice_type_code==='026'||item.invoice_type_code==='028'||item.invoice_type_code==='01'||item.invoice_type_code==='02'">
            发票号：
            <a v-if="item.void_status === 0 && item.is_download === '1'" style="color: red" @click="downloadInvoiceFile(item)">{{ item.invoice_no }}</a>
            <span v-if="item.void_status === 1">{{ item.invoice_no }}<a-tag color="red">失效</a-tag></span>
            <a v-if="item.void_status === 0 && item.is_download === '0'" @click="downloadInvoiceFile(item)">{{ item.invoice_no }}</a>
            / 开票时间：{{ item.created_at }}</span>
          <span v-else>发票号：{{ item.invoice_no }} / 开票时间：{{ item.created_at }}</span>
        </div>
      </div>
      <div slot="fmsLogsItem" slot-scope="text, record">
        <div v-for="item in record.fms_capital_claim_logs" :key="item.id">
          <span>认领金额：{{ item.claim_amount }}认领人：{{ item.claim_ant_name }}</span>
        </div>
      </div>
    </s-table>
    <a-modal
      :visible="printInvoiceSheetVisible"
      :confirmLoading="printInvoiceSheetLoading"
      :width="1520"
      :maskClosable="false"
      title="打印开票单"
      @cancel="printInvoiceSheetVisible = false">
      <div id="printInvoiceSheetBill">
        <InvoiceSheetBill
          :currentData="currentData"
          :balanceData="invoiceData"
        ></InvoiceSheetBill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printInvoiceSheetBillBtn"
                  v-print="printInvoiceSheetBill">确认打印</a-button>
        <a-button type="primary" @click="printInvoiceSheetVisible = false">关闭</a-button>
      </template>
    </a-modal>
    <a-modal
      :visible="emailFormVisible"
      :confirmLoading="emailConfirmLoading"
      :width="900"
      :maskClosable="false"
      title="电子发票发送邮件"
      @cancel="emailFormVisible = false"
      @ok="handleSendEmailSubmit"
    >
      <a-form-model layout="vertical" :model="emailForm" ref="emailForm">
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item label="电子发票号">
              <a-input v-model="emailForm.invoice_nos"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="邮箱地址">
              <a-select mode="tags" v-model.trim="emailForm.emails" style="width: 100%" @search="handleSearchEmails" @change="handleChangeEmail">
                <a-select-option v-for="item in invoiceEmails" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { filterOption } from '@/utils/util'
import itmsBill from '@/views/fms/bill/FmsItmsBill';
import { getWmsBalanceFunc } from '@/api/wms/sale'
import { getInvoiceSheetBill, getEInvoiceAndSendEmail, likeSearchEmail, togetherSendEmail } from '@/api/fms'
import InvoiceSheetBill from '@/views/fms/bill/InvoiceSheetBill';
import { downloadExcel, getCustomer } from '@/api/common'
import debounce from 'lodash/debounce';

export default {
  name: 'SaleBalance',
  components: {
    STable,
    itmsBill,
    InvoiceSheetBill
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      printInvoiceSheetVisible: false,
      printInvoiceSheetLoading: false,
      printInvoiceSheetBill: {
        id: `printInvoiceSheetBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      },
      invoiceData: [],
      currentData: {},
      queryParam: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '开票单号',
          dataIndex: 'balance_num'
        },
        {
          title: '开票状态',
          dataIndex: 'fee_status',
          scopedSlots: { customRender: 'feeStatusItem' }
        },
        {
          title: `订单号`,
          dataIndex: `business_no`,
          scopedSlots: { customRender: 'businessNo' }
        },
        {
          title: '业务类型',
          dataIndex: 'order_type',
          customRender: (text) => {
            let str = ''
            if (text.includes(1)) {
              str += '出库'
            }
            if (text.includes(2)) {
              str += '代销'
            }
            return str
          }
        },
        {
          title: '开票信息',
          dataIndex: 'fees',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '委托客户',
          dataIndex: 'customer_name'
        },
        {
          title: '税率',
          dataIndex: 'tax_rate'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '发票信息',
          dataIndex: 'invoices',
          scopedSlots: { customRender: 'invoiceItem' }
        },
        {
          title: '资金认领信息',
          dataIndex: 'fms_capital_claim_logs',
          scopedSlots: { customRender: 'fmsLogsItem' }
        },
        {
          title: '申请人',
          dataIndex: 'applicant_name'
        },
        {
          title: `申请时间`,
          dataIndex: `apply_time`
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          width: 110,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        return getWmsBalanceFunc('get_wms_balance_page', Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      showTotal: '开票金额合计：0; 开票数量合计：0',
      feeStatusList: [
        { value: 3, name: `商务待审核` },
        { value: 4, name: `商务审核被驳回` },
        { value: 5, name: `待开票` },
        { value: 6, name: `已开票` }
      ],
      orderTypeList: [
        { value: 1, name: `出库` },
        { value: 2, name: `代销` }
      ],
      claimStatusList: [
        { value: 1, name: `已认领` },
        { value: 2, name: `未认领` }
      ],
      buyerOps: [],
      customerInfo: undefined,
      fetching: false,
      selectedBalamceInfo: {},
      emailFormVisible: false,
      emailConfirmLoading: false,
      emailForm: {
        invoice_no: null,
        emails: []
      },
      invoiceEmails: []
    }
  },
  mounted() {
    this.getBalanceTotal()
  },
  methods: {
    filterOption,
    // 查询
    queryList() {
      this.$refs.table.refresh(true)
      this.getBalanceTotal()
    },
    // 查看贸易结算单开票总金额、总单数
    getBalanceTotal() {
      getWmsBalanceFunc('getBalanceTotal', this.queryParam).then(res => {
        this.showTotal = `开票金额合计：${res.total}; 开票数量合计：${res.num}`
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.buyerOps = [];
      this.fetching = true;
      getCustomer({
        department: 8,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.buyerOps = res;
        this.fetching = false;
      });
    },
    changeCustomerInfo(value) {
      this.customerInfo = value;
      const ids = [];
      if (value.length !== 0) {
        value.forEach(item => ids.push(item.key));
      }
      this.queryParam['customer_ids'] = ids;
      this.buyerOps = [];
      this.fetching = false;
    },
    // 导出贸易预收表
    tradeNoInvoicedSheet() {
      getWmsBalanceFunc('exportTradeNoInvoicedSheet').then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    // 导出贸易应收表
    tradeDebtSheet(type) {
      const reqParam = {
        export_type: type,
        customer_ids: this.queryParam['customer_ids'],
        claim_status: this.queryParam.claim_status
      }
      getWmsBalanceFunc('exportTradeDebtSheet', reqParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      });
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        this.queryParam['apply_start_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['apply_end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['apply_start_time'] = null
        this.queryParam['apply_end_time'] = null
      }
    },
    onDateChange2(date, dateString) {
      if (dateString[0]) {
        this.queryParam['invoice_start_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['invoice_end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['invoice_start_time'] = null
        this.queryParam['invoice_end_time'] = null
      }
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    // 打印开票单
    printInvoiceSheet(record) {
      if (record.id) {
        getInvoiceSheetBill({
          auditRecordId: record.id
        }).then(res => {
          this.invoiceData = res.list;
          record.billType = `开票`;
          record.apply_remark = record.remark;
          this.currentData = record;
          this.currentData.customer = this.currentData.customer_name
          this.currentData['paymentInfo'] = res.paymentInfo;
          this.currentData['department'] = '进销存';
          this.printInvoiceSheetVisible = true;
        })
      } else {
        this.$message.error('暂无记录')
      }
    },
    handleSendEmail(record) {
      if (!record.invoices) {
        this.$message.error('结算单没有开票信息')
        return
      }
      this.emailFormVisible = true
      this.emailForm = {
        invoice_nos: [],
        emails: []
      }
      record.invoices.forEach(element => {
        this.emailForm.invoice_nos.push(element.invoice_no);
      });
    },
    downloadInvoiceFile(item) {
      getEInvoiceAndSendEmail({ 'invoice_no': item.invoice_no, 'is_business': '1' }).then(v => {
        item.is_download = '1';
        if (v) {
          window.open(v, true);
        }
      })
    },
    handleSearchEmails(value) {
      if (value && value.length > 2) {
        likeSearchEmail({ 'email': value }).then(v => {
          this.invoiceEmails = v
        })
      }
    },
    handleChangeEmail(value) {
      console.info(value)
      this.emailForm.emails = []
      value.forEach(element => {
        this.emailForm.emails.push(element)
      });
      console.info(this.emailForm.emails)
    },
    handleSendEmailSubmit() {
      this.$refs.emailForm.validate(valid => {
        if (valid) {
          togetherSendEmail(this.emailForm)
            .then(_ => {
              this.emailConfirmLoading = false
              this.emailFormVisible = false
              this.$notification['success']({
                message: '提示',
                description: '发送电子发票邮件成功'
              })
              this.$refs.table.refresh(this.modalFlag === 'create')
            })
            .catch(_ => {
              this.emailConfirmLoading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped>

.balanceTitle{
  font-size: 20px;
  font-weight: 600;
}

</style>
